import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "components/CustomButtons/Button.jsx"
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx"
import SEO from "../components/seo"
import LayoutCard from "../components/LayoutCard"

const styles = {
  loginPageStyle,
}

const Cotizador = () => {
  return (
    <>
      <SEO title="Contacto" />
      <LayoutCard>
        <section className="bg-light p-5 rounded">
          <div class="container">
            <div class="section section-contacts">
              <div class="row">
                <div class="col-md-8 mx-auto">
                  <form name="contact" method="POST" data-netlify="true">
                    <h2 class="text-center title text-dark">Contacto</h2>
                    <h4 class="text-center text-dark">
                      Será un gusto saber de tí.
                    </h4>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        {/* habilita el formulario en netlify */}
                        <input type="hidden" name="form-name" value="contact" />
                        <label class="bmd-label-floating">Nombre</label>
                        <input
                          class="form-control"
                          name="nombre"
                          type="text"
                          required=""
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="bmd-label-floating">
                          Número de personas
                        </label>
                        <input
                          class="form-control"
                          name="numero personas"
                          type="number"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label class="bmd-label-floating">
                          Número de teléfono
                        </label>
                        <input
                          class="form-control"
                          name="numero de telefono"
                          type="tel"
                          required=""
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Fecha del evento</label>
                        <input
                          name="fecha evento"
                          class="form-control"
                          type="date"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="bmd-label-floating">
                        Correo electronico
                      </label>
                      <input
                        name="email"
                        class="form-control"
                        type="email"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label class="bmd-label-floating">Mensaje</label>
                      <textarea
                        class="form-control"
                        required=""
                        rows="5"
                        name="mensaje"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <div class="col-md-4 ml-auto mr-auto text-center">
                        <Button type="submit" color="primary">
                          Enviar mensaje
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutCard>
    </>
  )
}

export default withStyles(styles)(Cotizador)
